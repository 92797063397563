import { Rewards } from "../rewards";
import { LocationName } from "../types/LocationName";
import { RewardLocation, RewardLocationType } from "../types/RewardLocation";

export const landOfDragonsRewardLocations: RewardLocation[] = [
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Bamboo Grove",
		value: "11CDF72A",
		reward: Rewards.DARK_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Bamboo Grove",
		value: "11CDF736",
		reward: Rewards.ETHER,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Bamboo Grove",
		value: "11CDF742",
		reward: Rewards.MYTHRIL_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Checkpoint",
		value: "11CDF74E",
		reward: Rewards.HI_POTION,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Checkpoint",
		value: "11CDF75A",
		reward: Rewards.MYTHRIL_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Mountain Trail",
		value: "11CDF766",
		reward: Rewards.LIGHTNING_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Mountain Trail",
		value: "11CDF772",
		reward: Rewards.RECOVERY_RECIPE,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Mountain Trail",
		value: "11CDF77E",
		reward: Rewards.ETHER,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Mountain Trail",
		value: "11CDF78A",
		reward: Rewards.MYTHRIL_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Village Cave",
		value: "11CDF796",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Village Cave",
		value: "11CDF7A2",
		reward: Rewards.DARK_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Ridge",
		value: "11CDF7AE",
		reward: Rewards.FROST_SHARD,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Ridge",
		value: "11CDF7BA",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF7C6",
		reward: Rewards.TORN_PAGES,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF7D2",
		reward: Rewards.PALACE_MAP,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF7DE",
		reward: Rewards.AP_BOOST,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF7EA",
		reward: Rewards.QUEEN_RECIPE,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF7F6",
		reward: Rewards.AP_BOOST,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF802",
		reward: Rewards.OGRE_SHIELD,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF80E",
		reward: Rewards.MYTHRIL_CRYSTAL,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Throne Room",
		value: "11CDF81A",
		reward: Rewards.ORICHALCUM,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Village Cave Heartless",
		value: "21D10DF8",
		reward: Rewards.SLIDE_DASH,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Shan-Yu",
		value: "21D108C8",
		reward: Rewards.AERIAL_SWEEP,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Storm Rider",
		value: "21D10908",
		reward: Rewards.THUNDER,
		secondVisit: true,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Encampment Area Map",
		value: "11CE06D2",
		reward: Rewards.ENCAMPMENT_AREA_MAP,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.LAND_OF_DRAGONS,
		description: "AP Boost (Missions)",
		value: "11CE06C6",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Village Area Map",
		value: "11CE06DE",
		reward: Rewards.VILLAGE_AREA_MAP,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.LAND_OF_DRAGONS,
		description: "Hidden Dragon",
		value: "11CE06EA",
		reward: Rewards.HIDDEN_DRAGON,
	},
];
