import { Rewards } from "../rewards";
import { LocationName } from "../types/LocationName";
import { RewardLocation, RewardLocationType } from "../types/RewardLocation";

export const goofyRewardLocations: RewardLocation[] = [
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Starting Ability",
		value: "11D16F62",
		reward: Rewards.GOOFY_BASH,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Starting Ability",
		value: "11D16F64",
		reward: Rewards.ITEM_BOOST,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Shan-Yu",
		value: "21D108E8",
		reward: Rewards.GOOFY_TURBO,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "The Beast",
		value: "21D10968",
		reward: Rewards.DEFENDER,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Pete (Olympus Coliseum)",
		value: "21D10848",
		reward: Rewards.HYPER_HEALING,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Pete (Timeless River)",
		value: "21D109D8",
		reward: Rewards.GOOFY_TORNADO,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "The Interceptor Barrels",
		value: "21D10D58",
		reward: Rewards.SECOND_CHANCE,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Captain Barbossa",
		value: "11D10AC8",
		reward: Rewards.GOOFY_TEAMWORK,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Captain Barbossa",
		value: "11D10ACA",
		reward: Rewards.AUTO_LIMIT,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Treasure Room Heartless",
		value: "21D10E78",
		reward: Rewards.AUTO_HEALING,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Oogie Boogie",
		value: "21D10A48",
		reward: Rewards.ONCE_MORE,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Hyenas 1",
		value: "21D10EC8",
		reward: Rewards.LUCKY_LUCKY,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Hostile Program",
		value: "21D10C58",
		reward: Rewards.JACKPOT,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Storm Rider",
		value: "21D10928",
		reward: Rewards.GOOFY_TORNADO_FUSION,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Grim Reaper 1",
		value: "21D11048",
		reward: Rewards.DRAW,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Lock/Shock/Barrel",
		value: "21D10D98",
		reward: Rewards.AUTO_CHANGE,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Hyenas 2",
		value: "21D10EF8",
		reward: Rewards.MP_RAGE,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Zexion",
		value: "21D11178",
		reward: Rewards.DAMAGE_CONTROL,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Knight's Shield",
		value: "11CDF2E6",
		reward: Rewards.MP_HASTEGA,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Adamant Shield",
		value: "11CDF2F6",
		reward: Rewards.ITEM_BOOST,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Chain Gear",
		value: "11CDF306",
		reward: Rewards.DAMAGE_CONTROL,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Falling Star",
		value: "11CDF326",
		reward: Rewards.PROTECT,
	},
	{
		type: RewardLocationType.GOOFY,
		location: LocationName.GOOFY,
		description: "Dream Cloud",
		value: "11CDF336",
		reward: Rewards.PROTECTRA,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Knight Defender",
		value: "11CDF346",
		reward: Rewards.PROTECTGA,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Ogre Shield",
		value: "11CDF316",
		reward: Rewards.DEFENDER,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Genji Shield",
		value: "11CDF356",
		reward: Rewards.HYPER_HEALING,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Akashic Record",
		value: "11CDF366",
		reward: Rewards.MP_HASTE,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Nobody Guard",
		value: "11CDF376",
		reward: Rewards.MP_RAGE,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Frozen Pride+",
		value: "11CDF696",
		reward: Rewards.MP_HASTEGA,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Save The King+",
		value: "11CDF5E6",
		reward: Rewards.DAMAGE_CONTROL,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Majestic Mushroom",
		value: "11CDF6C6",
		reward: Rewards.PROTECT,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Majestic Mushroom+",
		value: "11CDF6D6",
		reward: Rewards.PROTECTRA,
	},
	{
		type: RewardLocationType.SHIELD,
		location: LocationName.GOOFY,
		description: "Ultimate Mushroom",
		value: "11CDF6E6",
		reward: Rewards.PROTECTGA,
	},
];
