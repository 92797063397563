import { Rewards } from "../rewards";
import { LocationName } from "../types/LocationName";
import { RewardLocation, RewardLocationType } from "../types/RewardLocation";

export const spaceParanoidsRewardLocations: RewardLocation[] = [
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Pit Cell",
		value: "11CDFCEE",
		reward: Rewards.PIT_CELL_AREA_MAP,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Pit Cell",
		value: "11CDFCFA",
		reward: Rewards.MYTHRIL_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Canyon",
		value: "11CDFD06",
		reward: Rewards.DARK_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Canyon",
		value: "11CDFD12",
		reward: Rewards.MYTHRIL_STONE,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Canyon",
		value: "11CDFD1E",
		reward: Rewards.MYTHRIL_GEM,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Canyon",
		value: "11CDFD2A",
		reward: Rewards.FROST_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Hallway",
		value: "11CDFD36",
		reward: Rewards.POWER_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Hallway",
		value: "11CDFD42",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Communications Room",
		value: "11CDFD4E",
		reward: Rewards.IO_TOWER_MAP,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Communications Room",
		value: "11CDFD5A",
		reward: Rewards.GAIA_BELT,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Central Computer Core",
		value: "11CDFD66",
		reward: Rewards.AP_BOOST,
		secondVisit: true,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Central Computer Core",
		value: "11CDFD72",
		reward: Rewards.ORICHALCUM_PLUS,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Central Computer Core",
		value: "11CDFD7E",
		reward: Rewards.COSMIC_ARTS,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.SPACE_PARANOIDS,
		description: "Central Computer Core",
		value: "11CDFD8A",
		reward: Rewards.CENTRAL_COMPUTER_CORE_MAP,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.SPACE_PARANOIDS,
		description: "Hostile Program",
		value: "21D10C38",
		reward: Rewards.VICINITY_BREAK,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.SPACE_PARANOIDS,
		description: "Solar Sailer Heartless",
		value: "21D11078",
		reward: Rewards.EXPLOSION,
		secondVisit: true,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.SPACE_PARANOIDS,
		description: "MCP",
		value: "21D10C78",
		reward: Rewards.REFLECT,
		secondVisit: true,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.SPACE_PARANOIDS,
		description: "Photon Debugger",
		value: "11CE0816",
		reward: Rewards.PHOTON_DEBUGGER,
		secondVisit: true,
	},
];
