import { Rewards } from "../rewards";
import { LocationName } from "../types/LocationName";
import { RewardLocation, RewardLocationType } from "../types/RewardLocation";

export const twtnwRewardLocations: RewardLocation[] = [
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Fragment Crossing",
		value: "11CE0402",
		reward: Rewards.MYTHRIL_STONE,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Fragment Crossing",
		value: "11CE040E",
		reward: Rewards.MYTHRIL_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Fragment Crossing",
		value: "11CE041A",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Fragment Crossing",
		value: "11CE0426",
		reward: Rewards.ORICHALCUM,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Memory's Skyscaper",
		value: "11CE0432",
		reward: Rewards.MYTHRIL_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Memory's Skyscaper",
		value: "11CE043E",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Memory's Skyscaper",
		value: "11CE044A",
		reward: Rewards.MYTHRIL_STONE,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.TWTNW,
		description: "The Brink of Despair",
		value: "11CE0456",
		reward: Rewards.DARK_CITY_MAP,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "The Brink of Despair",
		value: "11CE0462",
		reward: Rewards.ORICHALCUM_PLUS,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Nothing's Call",
		value: "11CE046E",
		reward: Rewards.MYTHRIL_GEM,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Nothing's Call",
		value: "11CE047A",
		reward: Rewards.ORICHALCUM,
	},
	{
		type: RewardLocationType.BIGCHEST,
		location: LocationName.TWTNW,
		description: "Twilight's View",
		value: "11CE0486",
		reward: Rewards.COSMIC_BELT,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Naught's Skyway",
		value: "11CE0492",
		reward: Rewards.MYTHRIL_GEM,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Naught's Skyway",
		value: "11CE049E",
		reward: Rewards.ORICHALCUM,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Naught's Skyway",
		value: "11CE04AA",
		reward: Rewards.MYTHRIL_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Ruin and Creation's Passage",
		value: "11CE04B6",
		reward: Rewards.MYTHRIL_STONE,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Ruin and Creation's Passage",
		value: "11CE04C2",
		reward: Rewards.AP_BOOST,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Ruin and Creation's Passage",
		value: "11CE04CE",
		reward: Rewards.MYTHRIL_CRYSTAL,
	},
	{
		type: RewardLocationType.CHEST,
		location: LocationName.TWTNW,
		description: "Ruin and Creation's Passage",
		value: "11CE04DA",
		reward: Rewards.ORICHALCUM,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.TWTNW,
		description: "Roxas",
		value: "21D111E8",
		reward: Rewards.COMBO_MASTER,
	},
	{
		type: RewardLocationType.BONUS,
		location: LocationName.TWTNW,
		description: "Luxord",
		value: "21D10B58",
		reward: Rewards.MAGNET,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Two Become One",
		value: "11CE0972",
		reward: Rewards.TWO_BECOME_ONE,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Oblivion",
		value: "11CE097E",
		reward: Rewards.OBLIVION,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Castle That Never Was Map",
		value: "11CE098A",
		reward: Rewards.CASTLE_THAT_NEVER_WAS_MAP,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 3 (Xigbar)",
		value: "11CE09BA",
		reward: Rewards.REPORT_THREE,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 8 (Roxas)",
		value: "11CE09F6",
		reward: Rewards.REPORT_EIGHT,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 9 (Luxord)",
		value: "11CE0A02",
		reward: Rewards.REPORT_NINE,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 11 (Before Xemnas 1)",
		value: "11CE0A1A",
		reward: Rewards.REPORT_ELEVEN,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 12 (Saix)",
		value: "11CE0A26",
		reward: Rewards.REPORT_TWELVE,
	},
	{
		type: RewardLocationType.POPUP,
		location: LocationName.TWTNW,
		description: "Secret Ansem's Report 13 (Xemnas 1)",
		value: "11CE0A32",
		reward: Rewards.REPORT_THIRTEEN,
	},
];
